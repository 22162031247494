/* MAP */

.map-wrap {
    position: relative;
    z-index: 20;

    #map {
        height: 700px;
    }
}

#map {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .infoBox {
        margin-left: 170px;
        margin-bottom: -100px;
    }
}

.map-marker-container {
    position: absolute;
    margin-top: 10px;
    transform: translate3d(-50%, -100%, 0);

    .marker-container {
        position: relative;
        top: 25px;
        left: 10px;
        width: 46px;
        height: 46px;
        z-index: 1;
        border-radius: 50%;
        cursor: pointer;
        -webkit-perspective: 1000;

        .marker-card {
            width: 100%;
            height: 100%;
            transform-style: preserve-3d;
            position: absolute;
            z-index: 1;

            .face {
                position: absolute;
                width: 20px;
                height: 20px;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                text-align: center;
                color: #fff;
                z-index: 100;
                border-radius: 50%;
                box-sizing: content-box;
                background-clip: content-box;
                line-height: 46px;
                font-size: 24px;
                &.front {
                    img {
                        position: absolute;
                        left: -25px;
                        top: -19px;
                        height: 70px;
                        width: 70px;
                    }
                }
            }

            .face::after {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                -ms-border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                transform: translate(-20%, -20%);
                box-shadow: 0px 0px 0px 10px rgba(226, 116, 71, 0.2);
                -webkit-animation: ripple 2s infinite;
                animation: ripple 2s infinite;
            }

            .face::after {
                content: "";
                position: absolute;
                -webkit-animation-delay: 0.2s;
                animation-delay: 0.2s;
            }

            .face > div {
                position: absolute;
                height: 100%;
                width: 100%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                z-index: 99;
            }
        }
    }
}

.map-listing-item {
    position: relative;

    .inner-box {
        @include flex(center, center);

        position: relative;
        border-radius: 10px;
        background: #ffffff;
        width: max-content;
        box-shadow: 0px 10px 25px rgba(54, 95, 104, 0.1);
        @include d-flex();
        gap: 10px;
        padding: 10px;

        .infoBox-close {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 26px;
            height: 26px;
            @include flex(center, center);
            color: var(--Secondary);
            font-size: 20px;
            border-radius: 50%;
            z-index: 9;
            text-align: center;
            cursor: pointer;
            transition: all 300ms ease;
        }

        .image-box {
            .image {
                width: 75px;
                height: 75px;

                img {
                    max-width: 100%;
                    border-radius: 8px;
                    transition: all 500ms ease;
                }
            }
        }

        .content {
            position: relative;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .icon-box {
                @include flex(center, center);
                gap: 10px;

                .item {
                    @include flex(center, center);
                    gap: 5px;
                    p {
                        font-family: "DM Sans", sans-serif;
                    }
                    i {
                        font-size: 17px;
                    }
                }
            }

            .price {
                font-weight: 500;
                font-size: 17px;
                line-height: 28px;
                color: var(--Third);
            }

            h4 {
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;
                color: var(--Secondary);
                margin-bottom: 0 !important;
            }
        }
    }
}

.cluster-map-visible {
    text-align: center;
    font-size: 20px !important;
    color: #ffffff !important;
    font-weight: 500 !important;
    border-radius: 50%;
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
    background-color: var(--Secondary);
    box-shadow: 0 7px 30px var(--Secondary);
    box-sizing: content-box;
    background-clip: content-box;
    
}

@keyframes ripple {
    0% {
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
    }

    50% {
        transform: translate(-50%, -50%) scale(0.5);
        opacity: 0.5;
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}
