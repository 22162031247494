// settings
$font-main: "DM Sans", sans-serif;
$font-main2: "Cardo", serif;
$font-main3: "Outfit", serif;
$fontIcon: "icomoon";
$fontIcon2: "flaticon_upskill";

:root {
    
    // bg
    --Black: #000000;
    --White: #ffffff;
    --Primary: #131836;
    --Secondary: #E27447;
    --Border: #E4E4E7;
    --Soft-Text: #585D69;
    --bg-1: #F3F3F3;
    --bg-2: #E9DECE;
    --bg-3: #D7C7EA;
    --bg-4: #FFEFEA;
    --bg-5:#F5F5F2;
}