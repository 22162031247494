@import url("./globalVariables.css");
@import url("./BoxShadowStyles.css");
@import url("./SeparatorStyles.css");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

iframe {
  border: none;
}

section[data-outscal-section] {
  width: 100%;
  max-width: var(--screen-lg);
  margin: auto;
}

.landing-page section[data-outscal-section] {
  max-width: var(--screen-xl);
}

.course-details-page section[data-outscal-section] {
  max-width: var(--screen-lg);
}

.section-page-course {
  max-width: var(--screen-xl);
}

.jobs-action-page section[data-outscal-section] {
  max-width: var(--screen-md);
}

.jobs {
  padding: 0 !important;
}

.job-page {
  background-color: var(--bg-light);
  height: 90.5vh;
  overflow: auto;
}

.job-page > div {
  max-width: var(--screen-lg);
  margin: auto;
  background-color: transparent;
  overflow-x: hidden;
}

.jobs-wrapper-section {
  max-width: 1100px;
}

section[data-outscal-section] .jobs-wrapper-section {
  padding-bottom: 0;
}

section[data-outscal-section].pricing-section {
  padding: 0 180px;
}

.swiper {
  margin: auto;
  width: 100%;
  padding: 10px;
}

.swiper-slide {
  --swiper-slide-width: 320px;
  padding: 10px;
  width: var(--swiper-slide-width) !important;
}

#job-slide {
  padding: 0px;
}

.testing {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/***** Global scrollbar styles - START */
body {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

body::-webkit-scrollbar {
  display: none;
}

body.show-scrollbar {
  scrollbar-width: thin;
  -ms-overflow-style: scrollbar;
}

body.show-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body.show-scrollbar::-webkit-scrollbar-track {
  background: var(--white);
}

body.show-scrollbar::-webkit-scrollbar-thumb {
  background: var(--custom-scrollbar-thumb-bg);
  border-radius: 10px;
}

body.show-scrollbar::-webkit-scrollbar-thumb:hover {
  background: var(--custom-scrollbar-thumb-hover-bg);
}

/***** Global scrollbar styles - END */

a {
  text-decoration: none;
  color: black;
}

* {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell,
    Droid Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
}

.generic-tooltip {
  z-index: 10000;
}

pre:has(> code.language-js) {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #eeeeee;
  border-radius: 6px;
  width: fit-content;
}

.img-parent {
  overflow: hidden;
  position: relative;
}

.overflow-auto {
  overflow: auto !important;
}

.table-header-input label {
}

.table-header-input input {
  padding: 0 !important;
}

.table-header-input {
  width: max-content;
}

.header-disabled {
  width: max-content;
  color: black !important;
}

.table-header-cell {
  padding: 6px !important;
}

th.sticky-table-column {
  z-index: 4 !important;
}

.sticky-table-column {
  z-index: 3 !important;
  position: sticky !important;
  right: 0;
  background-color: inherit;
}

.table-cell {
  padding: 6px !important;
  padding-left: 30px !important;
}

.bold-header {
  font-weight: 900;
}

.bold-header label {
  font-weight: 900;
  color: black;
}

.table-sort {
  margin-top: 16px;
  cursor: pointer;
}

.table-header-cell > div {
  display: flex;
}

.table-footer {
  justify-content: center !important;
  display: flex !important;
}

.table-drop {
  display: flex !important;
  width: 100%;
  height: auto;
  gap: 50px;
}

.table-drop-pair {
  display: flex;
  max-width: max-content;
  gap: 20px;
  min-width: 150px;
  width: fit-content;
  justify-content: space-between;
}

.table-drop-key {
  font-weight: 500;
  width: auto;
}

.table-drop-value {
  width: auto;
}

.stick-to-toolbar {
  position: absolute;
  top: 40px !important;
}

.lexical-lms-toolbar {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 108px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
}

.fullscreen {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
  margin: auto;
  inset: 0;
  z-index: 100;
  background: #000000a4;
}

.lexical-image {
  position: relative;
}

.lexical-image span {
  display: none;
}

.lexical-image span:hover {
  position: absolute;
  bottom: 5px;
  left: 5px;
}

.lexical-lms-toolbar > div {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.image-resizer {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: rgb(60, 132, 244);
  border: 1px solid #fff;
}

.image-resizer.image-resizer-n {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}

.image-resizer.image-resizer-ne {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

.image-resizer.image-resizer-e {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}

.image-resizer.image-resizer-se {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}

.image-resizer.image-resizer-s {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}

.image-resizer.image-resizer-sw {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}

.image-resizer.image-resizer-w {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}

.image-resizer.image-resizer-nw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}

.editor-image {
  display: block;
}

.editor-image img.focused {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}

/* Custom Scrollbar Style */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--custom-scrollbar-thumb-bg) var(--white);
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: var(--white);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: var(--custom-scrollbar-thumb-bg);
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: var(--custom-scrollbar-thumb-hover-bg);
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.sidebar-course-content {
  border: 1px solid var(--Border) !important;
  border-radius: 12px !important;
  box-shadow: 0px 0px 15px 0px rgba(19, 24, 54, 0.08) !important;
  padding: 30px 40px 30px 39px !important;
  margin: 0.5rem !important;
}

.sidebar-course-content ul {
  gap: 10px !important;
}

.sidebar-course-content .course-list ul li {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  gap: 9px !important;
}

.sidebar-course-content .course-list ul {
  padding-top: 4px !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 9px !important;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

div,
section {
  scroll-margin-top: 100px;
}

@supports not (scroll-margin-top: 0) {
  div,
  section {
    scroll-snap-margin-top: 100px;
  }
}

@media screen and (max-width: 767px) {
  section[data-outscal-section].pricing-section {
    padding: 20px;
  }

  .course-details-page section[data-outscal-section] {
    max-width: var(--screen-xl);
    padding: 20px 20px 70px 20px;
  }

  .section-page-course {
    max-width: var(--screen-xl);
    padding: 20px 0px 70px 0px;
  }
}

@media screen and (max-width: 768px) {
  .course-heading-center {
    text-align: center;
    font-weight: 700 !important;
  }
}
