// Theme Style
@import "/font/fonts.css";

.UI-V2 {
  h2 {
    font-family: "DM Sans", sans-serif !important;
  }

  @import "../css/bootstrap.css";
  @import "/css/animate.min.css";
  @import "/css/mmenu.css";
  @import "/css/swiper-bundle.min.css";
  @import "/css/magnific-popup.min.css";
  @import "./app.scss";

  // Font

  // Icons
  @import "/icons/flat/flaticon_upskill.css";
  @import "/icons/icomoon/style.css";
  @import "./custom.scss"; // .mm-panels.mm-panel.mm-panel--highest {
  // @import "node_modules/react-modal-video/scss/modal-video.scss";
  //   transform: none !important;
  // }
}